import React from "react";
import { Container, Row, Col } from "reactstrap";
import Layout from "../components/layout";
import Icon1 from "../assets/images/icon-newbuild.svg";
import Icon2 from "../assets/images/icon-remodeling.svg";
import Icon3 from "../assets/images/icon-garden.svg";
import Icon4 from "../assets/images/icon-garages.svg";
import Icon5 from "../assets/images/icon-extension.svg";
const ServicesPage = ({ data }) => {
  return (
    <Layout>
      <div className="hero internal py-3">
        <div className="bgimage">
        <Container>
          <Row>
            <Col md="12">
              <h1 className="pl-4 p-0 m-0">Services</h1>
            </Col>
          </Row>
          </Container>
        </div>
      </div>

      <Container className="services p-4">
        <Row>
          <Col md="5 p-4">
            <h5 className="mb-4">We offer an end to end building service, but can also work with your own architect or existing plans. You will have a single point of contact who manages your project.</h5>
            <h5 className="mb-4">Our team of skilled tradesmen will provide you with quality workmanship and a conscientious attitude towards all aspects of the work we undertake - whether the project is large or small.{" "}</h5>

            <Row className="mt-4">
              <Col md="2 block green p-4">
                <img src={Icon5} alt="Services" />
                <p>Extensions</p>
              </Col>
              <Col md="2 block grey p-4">
                <img src={Icon2} alt="Services" />
                <p>Remodelling</p>
              </Col>
              <Col md="2 block black p-4">
                <img src={Icon1} alt="Services" />
                <p>New Build</p>
              </Col>
              <Col md="2 block green p-4">
                <img src={Icon3} alt="Services" />
                <p>Garden Rooms</p>
              </Col>
              <Col md="2 block black p-4">
                <img src={Icon4} alt="Services" />
                <p>Garages</p>
              </Col>
            </Row>
          </Col>
          <Col md="7 pr-0">
            <div className="box p-4 process">
              <h2 className="mb-3">Our process</h2>
              <h5>Free consultation</h5>
              <p className="mb-4">
                Darren will be your main point of contact from day one. He will
                have a site visit to discuss your requirements and talk you
                through your options and follow up your meeting with an initial
                estimate.
              </p>

              <h5>Design &amp; planning</h5>
              <p className="mb-4">
                Our architect can design your project ensuring that all your
                needs are met and that it compliments the existing architecture.
                We can also manage the entire planning process including working
                drawings and structural engineering requirements.
              </p>

              <h5>Construction</h5>
              <p className="mb-4">
                We offer a complete service. We pride ourselves on our attention
                to detail and our efficient and tidy work methods. Our work
                standards comply with health and safety regulations, and all
                work is fully supervised and passed by building control. Our
                clients benefit from our honest approach, guidance and
                collaborative work style.
              </p>

              <h5>Fit out</h5>
              <p className="mb-4">
                Our projects are completed to the highest standard, with
                attention to detail.
              </p>

              <h5>Insurance &amp; guarantees</h5>
              <p className="mb-4">
                We have public and employers liability insurance and can offer
                an independent guarantee via our membership with the Federation
                of Master Builders.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default ServicesPage;
